import React from 'react'
import { MDBSideNavLink, MDBSideNavCat, MDBSideNavNav, MDBSideNav, MDBIcon } from 'mdbreact'
import Logo from '../../src/static/BigLogoColors.png'
import { permissionsHelper } from '../utils/permissionsHelper'
import { userType } from '../utils/UserTypes'
import { authenticationService } from '../services/authService'

class SideNavigation extends React.Component {
    state = {
        permissions: []
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props !== nextProps || this.state !== nextState
    }

    createSecondLevelMenu(to, text, onClick) {
        return (
            <MDBSideNavLink to={to} onClick={() => {
                this.props.onLinkClick();
                onClick && onClick();
            }}>
                {text}
            </MDBSideNavLink>
        )
    }

    logout(event) {
        authenticationService.logout(event);
        window.location = "/auth";
        event.preventDefault();
    }

    render() {
        const { onLinkClick, logout } = this.props

        if (this.state.permissions.length === 0) {
            permissionsHelper.scopePermissions().then(_ => {
                this.setState({ permissions: _ })
            })
            return <div className="white-skin" />
        }
        const currentUser = this.props.currentUser

        return (
            <div className="white-skin">
                <MDBSideNav
                    logo={Logo}
                    bg="/img/side-nav-bg-2.png"
                    mask="strong"
                    fixed
                    href={'https://rentto.online/'}
                    breakWidth={this.props.breakWidth}
                    triggerOpening={this.props.triggerOpening}
                    style={{ transition: 'padding-left .3s' }}
                    className="side-nav--with-setting"
                >
                
                    {userType[currentUser.user.userType] === 'employee' && (
                        <MDBSideNavNav>
                            <MDBSideNavLink topLevel to="/main" onClick={onLinkClick}>
                                <MDBIcon icon="chart-pie mr-2" />
                                Главная
                            </MDBSideNavLink>

                            <MDBSideNavLink topLevel to="/objects" onClick={onLinkClick}>
                                <MDBIcon icon="city mr-2" />
                                Объекты
                            </MDBSideNavLink>
                            <MDBSideNavLink topLevel to="/rooms" onClick={onLinkClick}>
                                <MDBIcon icon="door-closed mr-2" />
                                Объект аренды
                            </MDBSideNavLink>

                            {/*Пока не включаем, раздел не доделан*/}
                            {/*<MDBSideNavLink topLevel to="/groups" onClick={onLinkClick}>*/}
                            {/*    <MDBIcon icon="door-closed mr-2" />*/}
                            {/*    Помещения по паспорту БТИ*/}
                            {/*</MDBSideNavLink>*/}


                            <MDBSideNavLink topLevel to="/owners" onClick={onLinkClick}>
                                <MDBIcon icon="money-check mr-2" />
                                Собственники
                            </MDBSideNavLink>

                            {this.state.permissions.find(_ => _.id === 2).value && (
                                <MDBSideNavLink topLevel to="/renters" onClick={onLinkClick}>
                                    <MDBIcon icon="briefcase mr-2" />
                                    Арендаторы
                                </MDBSideNavLink>
                            )}

                            <MDBSideNavLink topLevel to="/contracts" onClick={onLinkClick}>
                                <MDBIcon icon="file-contract mr-2" />
                                Договоры
                            </MDBSideNavLink>

                            <MDBSideNavCat
                                name="Финансы"
                                id="dashboard-cat"
                                icon="dollar-sign mr-2"
                            >
                                {this.createSecondLevelMenu('/scores', 'Счета')}
                                {this.createSecondLevelMenu('/accruals', 'Начисления')}
                                {this.createSecondLevelMenu('/income', 'Поступления')}
                            </MDBSideNavCat>

                            <MDBSideNavLink topLevel to="/appeals" onClick={onLinkClick}>
                                <MDBIcon icon="edit mr-2" />
                                Обращения
                            </MDBSideNavLink>

                            {/*{this.state.permissions.find(_ => _.id === 16).value && (*/}
                            {/*    <MDBSideNavLink topLevel to="/events" onClick={onLinkClick}>*/}
                            {/*        <MDBIcon icon="calendar-check mr-2" />*/}
                            {/*        Мероприятия*/}
                            {/*    </MDBSideNavLink>*/}
                            {/*)}*/}

                            <MDBSideNavLink topLevel to="/reports" onClick={onLinkClick}>
                                <MDBIcon icon="edit mr-2" />
                                Отчеты
                            </MDBSideNavLink>

                            <MDBSideNavLink topLevel to="/employees" onClick={onLinkClick}>
                                <MDBIcon icon="user-tie mr-2" />
                                Сотрудники
                            </MDBSideNavLink>

                            {this.state.permissions.some(
                                _ =>
                                    (_.id === 17 && _.value) ||
                                    (_.id === 18 && _.value) ||
                                    (_.id === 19 && _.value) ||
                                    (_.id === 20 && _.value)
                            ) && (
                                <MDBSideNavLink topLevel to="/admin-panel" onClick={onLinkClick}>
                                    <MDBIcon icon="tools mr-2" />
                                    Администрирование
                                </MDBSideNavLink>
                            )}

                        </MDBSideNavNav>
                    )}

                    {userType[currentUser.user.userType] === 'renter' && (
                        <MDBSideNavNav >
                            <MDBSideNavLink topLevel to="/appeals" onClick={onLinkClick}>
                                <MDBIcon icon="edit mr-2" />
                                Обращения
                            </MDBSideNavLink>
                        </MDBSideNavNav>
                    )}
                    <MDBSideNavNav className={"navbar-profile-item"}>
                        <MDBSideNavNav >
                            <MDBSideNavLink topLevel to="/profile" >
                                <MDBIcon icon="user mr-2" />
                                Личный кабинет
                            </MDBSideNavLink>
                            <MDBSideNavLink topLevel to="/help" >
                                <MDBIcon icon="user mr-2" />
                                Помощь
                            </MDBSideNavLink>
                            <MDBSideNavLink topLevel to="/auth" onClick={() => {
                                logout();
                                this.logout();
                            }} >
                                <MDBIcon icon="sign-out-alt mr-2" />
                                Выйти
                            </MDBSideNavLink>
                        </MDBSideNavNav>
                    </MDBSideNavNav>

                </MDBSideNav>
            </div>
        )
    }
}

export default SideNavigation
